@tailwind base;
@tailwind components;
@tailwind utilities;

/*
===========================
Variables and global styles 
=========================== 
*/

@font-face {
    font-family: "Inconsolata";
    font-style: normal;
    font-weight: normal;
    src: url("../../public/fonts/Inconsolata.ttf");
}

@font-face {
    font-family: "Inconsolata";
    font-style: bold;
    font-weight: bold;
    src: url("../../public/fonts/Inconsolata-Bold.ttf");
}

:root {
    --bg-clr: #f8fafc;
    --font-clr: #0a161f;
    --outline-clr: #e37df5;
    --font-primary: 'Inconsolata';
    --font-secondary: "Inconsolata";
    --font-size-primary: 0.8rem;
    --font-size-seconadry: 1.2rem;
    --type-width: "500px";
}

[data-theme="light"] {
    --bg-clr: #f8fafc;
    --font-clr: #0a161f;
}

[data-theme="dark"] {
    --bg-clr: #0a161f;
    --font-clr: #f8fafc;
}

body {
    background-color: var(--font-clr);
    color: var(--font-clr);
    font-family: var(--font-primary);
    font-size: var(--font-size-primary);
}

input[type="checkbox"]:checked + .slider {
    -webkit-transform: translateX(90%);
    -ms-transform: translateX(90%);
    transform: translateX(90%);
}

label {
    border-color: var(--font-clr);
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

input[type="text"] {
    border: 1.5px solid var(--font-clr);
    border-radius: 0.2rem;
    padding: 0.2rem;
    color: #0a161f;
}

input[type="text"]:focus {
    outline: 2px solid var(--outline-clr);
    outline-offset: 2px;
}

textarea {
    border: 1.5px solid var(--font-clr);
    border-radius: 0.2rem;
    padding: 0.4rem;
    color: #0a161f;
}

textarea:focus {
    outline: 2px solid var(--outline-clr);
    outline-offset: 2px;
}

input[type="submit"] {
    background-color: var(--font-clr);
    color: var(--bg-clr);
    border-radius: 0.2rem;
    padding: 0.2rem;
}

button {
    background-color: var(--bg-clr);
    color: var(--font-clr);
    border: 2px solid transparent;
    padding: 0.2rem;
    transition: all 0.8s linear;
}

button:hover {
    border: 2px solid var(--font-clr);
    border-radius: 0.2rem;
}

hr {
    background-color: var(--font-clr);
}

a:hover {
    color: var(--outline-clr);
}

::-webkit-scrollbar {
    background-color: var(--bg-clr);
    width: 0.2rem;
}

::-webkit-scrollbar-track {
    background-color: var(--bg-clr);
    border-style: none;
}

::-webkit-scrollbar-thumb {
    background-color: var(--font-clr);
    width: 0.1rem;
    border-radius: 0.5rem;
}

/*
=============
Custom styles
============= 
*/

.slider {
    position: absolute;
    height: 94%;
    width: 46%;
    border: 2px solid var(--font-clr);
    border-radius: 1rem;
    transition: transform 0.4s linear;
}

.acc-card {
    background-color: var(--bg-clr);
    border-color: var(--bg-clr);
}

.card-show {
    visibility: visible;
}

.card-expand {
    flex-grow: 10;
}

.show-content {
    visibility: visible;
    opacity: 1;
}

.alert-show {
    visibility: visible;
}

.card-drop-x {
    -webkit-animation: card-drop-x 0.6s linear, bobble-x 0.2s 0.6s ease-in-out;
    animation: card-drop-x 0.6s linear, bobble-x 0.2s 0.6s ease-in-out;
}

.card-drop-y {
    -webkit-animation: card-drop-y 0.6s linear, bobble-y 0.2s 0.6s ease-in-out;
    animation: card-drop-y 0.6s linear, bobble-y 0.2s  0.6s ease-in-out;
}

.card-wiggle:hover {
    animation: wiggle 0.25s ease-in-out;
}

.alert-box {
    border: 2px solid var(--font-clr);
    animation: fade-out 0.6s linear;
    animation-delay: 7.5s;
}

.project-modal {
    background-color: var(--bg-clr);
}

.project-btn {
    background-color: var(--bg-clr);
    color: var(--font-clr);
    border: 2px solid var(--font-clr);
    padding: 0.2rem;
}

/* card drop x */
@-webkit-keyframes card-drop-x {
    0% {-webkit-transform: translateX(-120%);}
    50% { -webkit-transform: translateX(-50%);}
    100% {-webkit-transform: translateX(0%);}
}

@-moz-keyframes card-drop-x {
    0% {-webkit-transform: translateX(-120%);}
    50% { -webkit-transform: translateX(-50%);}
    100% {-webkit-transform: translateX(0%);}
}

@keyframes card-drop-x {
    0% {-webkit-transform: translateX(-120%);}
    50% { -webkit-transform: translateX(-50%);}
    100% {-webkit-transform: translateX(0%);}
}

@-webkit-keyframes card-drop-y {
    0% {-webkit-transform: translateY(-120%);}
    50% {-webkit-transform: translateY(-50%);}
    100% {-webkit-transform: translateY(0%);}
}

@-moz-keyframes card-drop-y {
    0% {-moz-transform: translateY(-120%);}
    50% {-moz-transform: translateY(-50%);}
    100% {-moz-transform: translateY(0%);}
}

@keyframes card-drop-y {
    0% {transform: translateY(-120%);}
    50% {transform: translateY(-50%);}
    100% {transform: translateY(0%);}
}

@-webkit-keyframes bobble-x {
    0%, 100% {-webkit-transform: translateX(0px);}
    50% {-webkit-transform: translateX(2%);}
}

@-moz-keyframes bobble-x {
    0%, 100% {-moz-transform: translateX(0px);}
    50% {-moz-transform: translateX(2%);}
}

@keyframes bobble-x {
    0%, 100% {transform: translateX(0px);}
    50% {transform: translateX(2%);}
}

@-webkit-keyframes bobble-y {
    0%, 100% {-webkit-transform: translateY(0px);}
    50% {-webkit-transform: translateY(2%);}
}

@-moz-keyframes bobble-y {
    0%, 100% {-moz-transform: translateY(0px);}
    50% {-moz-transform: translateY(2%);}
}

@keyframes bobble-y {
    0%, 100% {transform: translateY(0px);}
    50% {transform: translateY(2%);}
}

@-webkit-keyframes wiggle {
    0%, 50%, 100% {-webkit-transform: rotate(0deg);}
    25% {-webkit-transform: rotate(0.5deg);}
    75% {-webkit-transform: rotate(-0.5deg);}
}

@-moz-keyframes wiggle {
    0%, 50%, 100% {-moz-transform: rotate(0deg);}
    25% {-moz-transform: rotate(0.5deg);}
    75% {-moz-transform: rotate(-0.5deg);}
}

@keyframes wiggle {
    0%, 50%, 100% {transform: rotate(0deg);}
    25% {transform: rotate(0.5deg);}
    75% {transform: rotate(-0.5deg);}
}

@keyframes fade-out {
    0% {opacity: 1}
    50% {opactiy: 0.5}
    100% {opacity: 0;}
}

@media screen and (min-width: 768px) {
    body {
        font-size: var(--font-size-seconadry);
    }
}